<template>
    <div class="modal-produtos-container ">
        <div class="modal-produtos-container01 elevation-24">

            <h2>{{$t_('agende_visita')}}</h2>
            <div class="modal-produtos-container02">
                <div class="modal-produtos-container03" v-if="datas">
                    <div class="modal-produtos-container04">

                        <v-select
                            v-model="periodo"
                            color="black"
                            v-if="$carrinho.AddPedido.periodo"
                            prepend-icon="mdi-ticket-outline"
                            :items="$carrinho.AddPedido.periodo"
                            :label="$t_('selecione_produto')"
                            item-text="dsctipoproduto"
                            @click="clearHorario()"
                        ></v-select>
                        <v-row v-if="periodo">
                            <v-col cols="12" md="6">
                                <v-menu chips ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y class="datapicker">

                                    <v-date-picker 
                                    event-color="green lighten-1"
                                    :picker-date.sync="pickerDate"
                                    :multiple="multiple" style="width: 100% !important" v-model="dataApre" :color="$config.dados.corprimaria" :locale="$axios.getLocal('lang').data"
                                        :header-color="$config.dados.corprimaria" width="290" :landscape="$config.responsive() > 991 && !$config.mobile()"
                                        :allowed-dates="allowedDates" @change="save" elevation="15"
                                        
                                    >
                                    </v-date-picker>

                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field color="black" hide-selected @click="clearHorario()" v-model="dateFormated" :label="$t_('selecione_data')" 
                                            prepend-icon="mdi-calendar" v-bind="attrs" v-on="on">
                                        </v-text-field>
                                    </template>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="6" v-if="!carregando && dataApre">
                               <small>{{ $t_('carregando_horarios') }}</small>
                            </v-col>
                            <v-col cols="12" md="6" v-else-if="horarios_ && dataApre && (horarios_.id == dataApre || horarios_.id == dataApre[0])">
                                <v-select
                                    color="black"
                                    ref="dataHorario" 
                                    v-model="dataHorario"
                                    hide-details
                                    :disabled="horarios_.itens.length == 1"
                                    @click:append-outer="openClock"
                                    :items="horarios_.itens"
                                    item-text="hora"
                                    :label="$t_('selecione_horario')"
                                    return-object
                                    single-line
                                    prepend-icon="mdi-clock" 
                                ></v-select>
                            </v-col>
                        </v-row>
                        
                    </div>
                </div>
            </div>

            <div v-if="dataApre && periodo && (horarios_ && horarios_.itens.length > 0 && dataHorario)" style="max-height: 345px; overflow-x: auto; padding: 5px">
                <div class="" v-for="(tipo_produtos, x) in $carrinho.AddPedido.tipo_produtos" :key="x">
                    <template v-if="tipo_produtos.dsctipoproduto == periodo">
                        <!-- <h3 class="mt-3">{{ tipo_produtos.dsctipoproduto }}</h3>  -->
                        <div class="modal-produtos-container07" v-for="(item, index) in tipo_produtos.produtos" :key="index">
                            <div class="modal-produtos-container08">
                                <!-- <span :style="'background-color:' + $config.dados.corsecundaria + ';color:' + $config.dados.corprimaria" style="padding: 9px; font-size: 11px !important; margin: 0px 0px 17px 0px; border-radius: 5px;" v-html="item.prodhelp" v-if="item.prodhelp"></span> -->
                                <div class="modal-produtos-container09">
                                    <div class="modal-produtos-container10">
                                        <span class="modal-produtos-text01">{{ item.dscproduto }}</span>
                                        <div class="modal-produtos-container11" v-if="item.valor_sem_desconto > 0 && $config.dados.mostrarDescontoCombo">
                                            <span class="modal-produtos-text03-1" :style="'color: #AAAAAA'">{{ $t_('PRODUTO_DE') }}{{ item.valor_sem_desconto | moeda }}</span>
                                            <span class="modal-produtos-text04-1" :style="'color: #AAAAAA'">,{{ item.valor_sem_desconto | centavos }}</span>
                                        </div>
                                        <div class="modal-produtos-container11"  v-if="item.valor_sem_desconto > 0 && $config.dados.mostrarDescontoCombo">
                                            <span class="modal-produtos-text03" :style="'color:' + $config.dados.corprimaria">{{ $t_('PRODUTO_POR') }}{{ item.preco | moeda }}</span>
                                            <span class="modal-produtos-text04" :style="'color:' + $config.dados.corprimaria">,{{ item.preco | centavos }}</span>
                                        </div>
                                        <div class="modal-produtos-container11"  v-else>
                                            <span class="modal-produtos-text03" :style="'color:' + $config.dados.corprimaria">{{ item.preco | moeda }}</span>
                                            <span class="modal-produtos-text04" :style="'color:' + $config.dados.corprimaria">,{{ item.preco | centavos }}</span>
                                        </div>
                                    </div>
                                    <div class="modal-produtos-container12">
                                        <div class="modal-produtos-container13">
                                            <div class="modal-produtos-container14">
                                                <button :disabled="disabledMinus" class="modal-produtos-container15" :style="'background-color:' + $config.dados.corprimaria" @click="disabledButton(item), $carrinho.minus(item, { idapresentacao: dataHorario ? dataHorario.idapresentacao : $carrinho.AddPedido.idapresentacao, dt_apre: dataApre, dt_hora: dataHorario, dscapre: tipo_produtos.dsctipoproduto }, false)">
                                                    <svg viewBox="0 0 804.5714285714286 1024" class="modal-produtos-icon04">
                                                        <path
                                                            d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-694.857c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h694.857c30.286 0 54.857 24.571 54.857 54.857z">
                                                        </path>
                                                    </svg>
                                                </button>
                                            </div>
                                            <div class="modal-produtos-container16">
                                                <big :style="'color:' + $config.dados.corprimaria">{{ item.qtd }}</big>
                                            </div>
                                            <div class="modal-produtos-container17">
                                                <button :disabled="disabledAdd" class="modal-produtos-container18" :style="'background-color:' + $config.dados.corprimaria" @click="disabledButton(item), $carrinho.add(item, { idapresentacao: dataHorario ? dataHorario.idapresentacao : $carrinho.AddPedido.idapresentacao, dt_apre: dataApre, dt_hora: dataHorario, dscapre: tipo_produtos.dsctipoproduto }, false)">
                                                    <svg viewBox="0 0 804.5714285714286 1024" class="modal-produtos-icon06">
                                                        <path
                                                            d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z">
                                                        </path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <v-expansion-panels 
                                  v-if="item.prodhelp"
                                  v-model="item.ajuda_aberta"
                                  class="elevation-0 mb-2"
                                >
                                    <v-expansion-panel 
                                      dense
                                      v-for="(itemProd, i) in [item]" 
                                      :key="i" 
                                      class="elevation-0 pa-0 ma-0"
                                      :style="'background-color:' + $config.dados.corsecundaria + ';color:' + $config.dados.corprimaria" 
                                      style="margin: 0px; border-radius: 5px;"
                                    >
                                        <v-expansion-panel-header class="py-0 ma-0">
                                          <h4>{{ $t_('informacoes_ajuda')}}</h4> 
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content class="pa-0 ma-0">
                                          <div v-html="itemProd.prodhelp"></div>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                                <!-- END HELP ACCORDION -->
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MIXES from '@/mixeds/produto.mixeds.js'

export default {
    name: 'DetalheProdutos',
    mixins: [MIXES]
}
</script>

<style scoped>
big {
    font-size: 25px;
    color: #27787f;
    font-weight: 900;
}

.modal-produtos-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);
}
.modal-produtos-container01 {
  flex: 0 0 auto;
  width: 100%;
  margin: 15px;
  display: flex;
  padding: 15px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.modal-produtos-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 0px;
  align-items: center;
  margin-left: 0px;
  border-color: var(--dl-color-gray-700);
  border-style: dashed;
  border-width: 2px;
  margin-right: 0px;
  margin-bottom: 0px;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.modal-produtos-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
}
.modal-produtos-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: block;
  align-self: flex-start;
  align-items: center;
  border-color: var(--dl-color-gray-700);
  border-style: dashed;
  border-width: 1px;
  margin-bottom: 0px;
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.modal-produtos-container05 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  padding: 10px;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
}
.modal-produtos-icon {
  top: 18px;
  left: 24px;
  width: 24px;
  height: 24px;
  position: absolute;
}
.modal-produtos-textinput {
  color: var(--dl-color-gray-black);
  width: 100%;
  margin-top: 5px;
  border-color: var(--dl-color-gray-900);
  padding-left: 45px;
  margin-bottom: 5px;
}
.modal-produtos-container06 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  padding: 10px;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
}
.modal-produtos-icon02 {
  top: 26px;
  left: 18px;
  width: 27px;
  height: 27px;
  position: absolute;
}
.modal-produtos-textinput1 {
  color: var(--dl-color-gray-black);
  width: 100%;
  margin-top: 5px;
  border-color: var(--dl-color-gray-900);
  padding-left: 45px;
  margin-bottom: 5px;
}
.modal-produtos-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 15px;
  align-items: center;
  margin-left: 0px;
  border-color: var(--dl-color-gray-700);
  border-style: dashed;
  border-width: 2px;
  margin-right: 0px;
  margin-bottom: 15px;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.modal-produtos-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
}
.modal-produtos-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-color: var(--dl-color-gray-700);
  border-style: dashed;
  border-width: 1px;
  margin-bottom: 15px;
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.modal-produtos-container10 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.modal-produtos-text01 {
  color: black
}

.prodhelp {
  background: antiquewhite!important;
  color: gray!important;
  padding: 2px 2px!important;
}

.modal-produtos-container11 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.modal-produtos-text02 {
  color: var(--dl-color-principal);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-right: 5px;
}
.modal-produtos-text03 {
  color: var(--dl-color-principal);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.modal-produtos-text04 {
  color: var(--dl-color-principal);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.modal-produtos-text03-1 {
  color: var(--dl-color-principal);
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  text-decoration: line-through;
}
.modal-produtos-text04-1 {
  color: var(--dl-color-principal);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  text-decoration: line-through;
}
.modal-produtos-container12 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.modal-produtos-container13 {
  width: 120px;
  height: 35px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: 18px;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f1f1f1;
}
.modal-produtos-container14 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.modal-produtos-container15 {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
}

.modal-produtos-container15:active {
    background-color: var(--dl-color-secundaria)!important;
    color: white!important;
    width: 27px;
    height: 27px;
}

.modal-produtos-container18:active {
    background-color: var(--dl-color-secundaria)!important;
    color: white!important;
    width: 27px;
    height: 27px;
}

.modal-produtos-icon04 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin-top: 2px;
}
.modal-produtos-container16 {
  flex: 0 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.modal-produtos-container17 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.modal-produtos-container18 {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-principal);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
}
.modal-produtos-icon06 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin-top: 2px;
}
.modal-produtos-container19 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-color: var(--dl-color-gray-700);
  border-style: dashed;
  border-width: 1px;
  margin-bottom: 15px;
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.modal-produtos-container20 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.modal-produtos-text07 {
  color: var(--dl-color-gray-700);
}
.modal-produtos-container21 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.modal-produtos-text08 {
  color: var(--dl-color-principal);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-right: 5px;
}
.modal-produtos-text09 {
  color: var(--dl-color-principal);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.modal-produtos-text10 {
  color: var(--dl-color-principal);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.modal-produtos-container22 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.modal-produtos-container23 {
  width: 120px;
  height: 35px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: 18px;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f1f1f1;
}
.modal-produtos-container24 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.modal-produtos-container25 {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
}
.modal-produtos-icon08 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin-top: 2px;
}
.modal-produtos-container26 {
  flex: 0 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.modal-produtos-container27 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.modal-produtos-container28 {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
}
.modal-produtos-icon10 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin-top: 2px;
}



@media(max-width: 991px) {
  .modal-produtos-container {
    height: auto;
  }
  .modal-produtos-container01 {
    margin: 0px
  }
  .modal-produtos-container03 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .modal-produtos-container04 {
    flex-direction: column;
  }
  .modal-produtos-container05 {
    width: 100%;
  }
  .modal-produtos-container06 {
    width: 100%;
  }
  .modal-produtos-container08 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .modal-produtos-container15 {
    position: relative;
    align-self: center;
    border-radius: var(--dl-radius-radius-round);
  }
  .modal-produtos-icon04 {
    top: auto;
    fill: var(--dl-color-gray-white);
    left: 3px;
    right: auto;
    bottom: 2px;
    position: absolute;
  }
  .modal-produtos-container18 {
    width: 30px;
    height: 30px;
    position: relative;
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    justify-content: center;
    background-color: #d2232aff;
  }
  .modal-produtos-icon06 {
    top: auto;
    fill: var(--dl-color-gray-white);
    left: auto;
    right: 3px;
    bottom: 3px;
    position: absolute;
    align-self: center;
  }
  .modal-produtos-container25 {
    position: relative;
    align-self: center;
    border-radius: var(--dl-radius-radius-round);
  }
  .modal-produtos-icon08 {
    top: auto;
    fill: var(--dl-color-gray-white);
    left: 3px;
    right: auto;
    bottom: 2px;
    position: absolute;
  }
  .modal-produtos-container28 {
    width: 30px;
    height: 30px;
    position: relative;
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    justify-content: center;
    background-color: #d2232aff;
  }
  .modal-produtos-icon10 {
    top: auto;
    fill: var(--dl-color-gray-white);
    left: auto;
    right: 3px;
    bottom: 3px;
    position: absolute;
    align-self: center;
  }
}
@media(max-width: 767px) {
  .modal-produtos-container02 {
    height: auto;
    flex-direction: column;
  }
  .modal-produtos-container03 {
    width: 100%;
  }
  .modal-produtos-container04 {
    width: 100%;
  }
  .modal-produtos-container07 {
    height: auto;
    flex-direction: column;
  }
  .modal-produtos-container08 {
    width: 100%;
  }
  .modal-produtos-container09 {
    width: 100%;
  }
  .modal-produtos-container10 {
    width: 50%;
  }
  .modal-produtos-text01 {
    color: var(--dl-color-gray-700);
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }
  .modal-produtos-container14 {
    width: 30px;
  }
  .modal-produtos-container15 {
    width: 30px;
    height: 30px;
    position: relative;
    align-self: center;
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    justify-content: center;
    background-color: #d2232aff;
  }
  .modal-produtos-icon04 {
    top: auto;
    fill: var(--dl-color-gray-white);
    left: 3px;
    right: auto;
    bottom: 2px;
    position: absolute;
  }
  .modal-produtos-container17 {
    width: 30px;
  }
  .modal-produtos-container18 {
    width: 30px;
    height: 30px;
    position: relative;
    align-self: center;
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    justify-content: center;
    background-color: #d2232aff;
  }
  .modal-produtos-icon06 {
    top: auto;
    fill: var(--dl-color-gray-white);
    left: 3px;
    right: auto;
    bottom: 2px;
    position: absolute;
  }
  .modal-produtos-container19 {
    width: 100%;
  }
  .modal-produtos-container20 {
    width: 50%;
  }
  .modal-produtos-text07 {
    color: var(--dl-color-gray-700);
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }
  .modal-produtos-container24 {
    width: 30px;
  }
  .modal-produtos-container25 {
    width: 30px;
    height: 30px;
    position: relative;
    align-self: center;
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    justify-content: center;
    background-color: #d2232aff;
  }
  .modal-produtos-icon08 {
    top: auto;
    fill: var(--dl-color-gray-white);
    left: 3px;
    right: auto;
    bottom: 2px;
    position: absolute;
  }
  .modal-produtos-container27 {
    width: 30px;
  }
  .modal-produtos-container28 {
    width: 30px;
    height: 30px;
    position: relative;
    align-self: center;
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    justify-content: center;
    background-color: #d2232aff;
  }
  .modal-produtos-icon10 {
    top: auto;
    fill: var(--dl-color-gray-white);
    left: 3px;
    right: auto;
    bottom: 2px;
    position: absolute;
  }
}
@media(max-width: 479px) {
    .menuable__content__active {
        left: 43px !important
    }
  .modal-produtos-text02 {
    align-self: flex-start;
  }
  .modal-produtos-text03 {
    align-self: flex-start;
  }
  .modal-produtos-text04 {
    align-self: flex-start;
  }
  .modal-produtos-text08 {
    align-self: flex-start;
  }
  .modal-produtos-text09 {
    align-self: flex-start;
  }
  .modal-produtos-text10 {
    align-self: flex-start;
  }
}
</style>
